import React, { createContext, useEffect, useReducer, useRef } from "react";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
// import mixpanel from "mixpanel-browser";
// Firestore
import { collection, doc, onSnapshot, query, where, updateDoc } from "firebase/firestore";
import { firestore as db } from "contexts/FirebaseContext";
import { fromFirestoreFeatureToGeoJson } from "@foresting-tomorrow/database_lib";

// Types
import {
  EstatesAndMapDataProps,
  EstatesAndMapDataContextType,
  NewMapProps,
  NewEstateProps,
  NewProjectProps,
  EstateProps,
  AppUpgradeNotifications,
  AppUpgradeLimits,
  AppUpgradeLimitsReached,
} from "types/estatesAndMap";

// Reducer
import estatesAndMapsReducer from "store/reducers/EstatesAndMaps";

// action - state management
import {
  ESTATES_AND_MAPS_API_CALL_START,
  ESTATES_AND_MAPS_API_CALL_FAIL,
  ADDED_ESTATE_SUCCESS,
  MODIFIED_ESTATE_SUCCESS,
  REMOVED_ESTATE_SUCCESS,
  ADDED_MAP_SUCCESS,
  MODIFIED_MAP_SUCCESS,
  REMOVED_MAP_SUCCESS,
  SET_SELECTED_ESTATE_ID,
  SET_SELECTED_MAP_ID,
  SET_SELECTED_PROJECT_ID,
  SET_HAS_ZOOMED,
  SAVE_ESTATE_SUCCESS,
  SAVE_MAP_SUCCESS,
  SAVE_PROJECT_SUCCESS,
  DELETE_MAP_SUCCESS,
  DELETE_ESTATE_SUCCESS,
  DELETE_PROJECT_SUCCESS,
  ADDED_DATA_ROLES_SUCCESS,
  ADDED_ORG_ESTATE_SUCCESS,
  MODIFIED_ORG_ESTATE_SUCCESS,
  REMOVED_ORG_ESTATE_SUCCESS,
  ADDED_ORG_MAP_SUCCESS,
  MODIFIED_ORG_MAP_SUCCESS,
  REMOVED_ORG_MAP_SUCCESS,
  ADDED_PROJECT_SUCCESS,
  MODIFIED_PROJECT_SUCCESS,
  REMOVED_PROJECT_SUCCESS,
  ADDED_ORG_PROJECT_SUCCESS,
  MODIFIED_ORG_PROJECT_SUCCESS,
  REMOVED_ORG_PROJECT_SUCCESS,
  ADDED_TEAM_MEMBER_PROJECT_SUCCESS,
  MODIFIED_TEAM_MEMBER_PROJECT_SUCCESS,
  REMOVED_TEAM_MEMBER_PROJECT_SUCCESS,
  ADDED_TEAM_MEMBER_MAP_SUCCESS,
  MODIFIED_TEAM_MEMBER_MAP_SUCCESS,
  REMOVED_TEAM_MEMBER_MAP_SUCCESS,
  ESTATES_AND_MAPS_RESET_STATE,
  SET_APP_UPGRADE_NOTIFICATIONS,
  SET_APP_UPGRADE_LIMITS,
  SET_ESTATE_AND_MAP_KEYS_NOT_ALLOWED,
  SET_APP_UPGRADE_LIMITS_REACHED,
  SET_ESTATE_ROW_EXPANDED,
  SET_TEAM_ROW_EXPANDED,
  SET_CONTACT_ROW_EXPANDED,
} from "store/reducers/actions";

// Project import
import useAuth from "hooks/useAuth";
import useAppFunctionalityConfig from "hooks/useAppFunctionalityConfig";
import { useTranslation } from "utils/locales/utilityFunctions";
import { getAppConfigFromMapRole } from "config/utils/getAppConfig";
import { ActivityResponsible } from "types/firestoreFeatures";
import { CloudFunctionNames, DataShareItem } from "types/auth";
import { openSnackbar } from "store/reducers/snackbar";
import { TCountryCode } from "countries-list";
import { mixpanelEvents } from "config/servicesConfig/mixpanelEvents";
import { dataObjectWithoutUndefinedFields } from "utils/dataManipulation";

// Firestore event listeners
let firestoreEventListeners: any[] = [];
let privateDocumentEventListeners: { [key: string]: any } = {};
let firestoreTest: any[] = [];

// Initial state
const initialState: EstatesAndMapDataProps = {
  loading: false,
  error: null,
  selectedEstateId: null,
  selectedMapId: null,
  selectedProjectId: null,
  hasZoomed: null,
  estates: {},
  maps: {},
  projects: {},
  orgEstates: {},
  orgMaps: {},
  orgProjects: {},
  teamMaps: {},
  teamProjects: {},
  estateRowExpanded: {},
  teamRowExpanded: {},
  contactRowExpanded: {},
  appUpgradeNotifications: null,
  appUpgradeLimits: null,
  appUpgradeLimitsReached: null,
  estateAndMapKeysNotAllowed: null,
};

const EstatesAndMapsContext = createContext<EstatesAndMapDataContextType | null>(null);

export function EstatesAndMapsProvider({ children }: { children: React.ReactElement }) {
  const [state, dispatch] = useReducer(estatesAndMapsReducer, initialState);
  const snackDispatch = useDispatch();
  const { translate } = useTranslation();
  const { isLoggedIn, user, userOrg, orgTeam, selectedSpeciesSchemeId, setSelectedSpeciesSchemeId, fetchSpeciesScheme, callCloudFunction, setCountryCodes } = useAuth();
  const { config: appConfig, onChangeAppFunctionalityConfig } = useAppFunctionalityConfig();

  // Refs
  const orgEstatesFetchedRef = useRef(false);
  const userMapsAndProjectsFetchedRef = useRef(false);
  const orgElementEstateChangedIdRef = useRef<string | null>(null);
  let fetchedEstatesById: string[] = [];
  const firstRenderRef = useRef(true);
  const selectedMapIdRef = useRef<string | null>(null);
  const selectedProjectIdRef = useRef<string | null>(null);

  // --- Reset reducer state and unsubscribe from firestore event listeners on logout --- //
  useEffect(() => {
    if (!isLoggedIn) {
      // Unsubscribe from event listeners
      if (firestoreEventListeners.length > 0) {
        firestoreEventListeners.forEach((el) => el());
      }
      // Unsubscribe from private document event listeners
      if (Object.keys(privateDocumentEventListeners).length > 0) {
        console.info(
          "Unsubscribe from private document event listeners",
          privateDocumentEventListeners
        );
        Object.values(privateDocumentEventListeners).forEach((el) => {
          console.info("Unsub priv el", el);
          el();
        });
      }
      // Reset reducer state
      resetState();
    }
  }, [isLoggedIn]);

  // --- Set app upgrade notifications and limits --- //
  useEffect(() => {
    if (userOrg && userOrg.subscription) {
      // Notifications obejct
      let notifications: AppUpgradeNotifications = {
        ...{
          teamLimitReached: false,
          teamLimitExceeded: false,
          estateLimitReached: false,
          estateLimitExceeded: false,
          mapLimitReached: false,
          mapLimitExceeded: false,
          estateAndMapLimitReached: false,
          estateAndMapLimitExceeded: false,
          areaLimit95PercentReached: false,
          areaLimitReached: false,
          areaLimitExceeded: false,
          mapNotificationDismissed: false,
          trialSubscriptionDismissed: false,
        },
        ...state.appUpgradeNotifications,
      };
      // Limits object
      let limits: AppUpgradeLimits = {
        teamLimit: -1,
        estateLimit: -1,
        mapLimit: -1,
        areaLimit: -1,
      };
      let limitsReached: AppUpgradeLimitsReached = {
        teamLimitReached: false,
        estateLimitReached: false,
        mapLimitReached: false,
        areaLimitReached: false,
      };
      // Set limit values
      if (userOrg.subscription.type === "trial") {
        limits.teamLimit = appConfig.teamAndContactsPage.limits.maxNumberOfTeamMembers;
        limits.estateLimit = appConfig.estatesPage.limits.maxNumberOfEstates;
        limits.mapLimit = appConfig.estatesPage.limits.maxNumberOfMaps;
        limits.areaLimit =
          appConfig.accountPage.limits.maxNumberOfHa > 0
            ? appConfig.accountPage.limits.maxNumberOfHa
            : -1;
      } else {
        limits.teamLimit = userOrg.subscription.maxNumberOfTeamMembers
          ? userOrg.subscription.maxNumberOfTeamMembers
          : 1;
        limits.estateLimit = userOrg.subscription.maxNumberOfEstates
          ? userOrg.subscription.maxNumberOfEstates
          : 1;
        limits.mapLimit = userOrg.subscription.maxNumberOfMaps
          ? userOrg.subscription.maxNumberOfMaps
          : 1;
        limits.areaLimit = userOrg.subscription.maxNumberOfHa
          ? userOrg.subscription.maxNumberOfHa
          : 1000;
      }
      // Set app upgrade limits
      setAppUpgradeLimits(limits);

      // Set app upgrade notifications
      if (limits.teamLimit > 0 && userOrg && userOrg.users) {
        notifications.teamLimitReached = userOrg.users.length === limits.teamLimit;
        notifications.teamLimitExceeded = userOrg.users.length > limits.teamLimit;
      }

      let estateKeysNotAllowed: string[] = [];
      let mapKeysNotAllowed: string[] = [];
      if (limits.estateLimit > 0) {
        if (state.orgEstates) {
          notifications.estateLimitReached =
            Object.values<EstateProps>(state.orgEstates).filter((el) => el.type === "full")
              .length === limits.estateLimit;
          notifications.estateLimitExceeded =
            Object.values<EstateProps>(state.orgEstates).filter((el) => el.type === "full").length >
            limits.estateLimit;
        }
      }
      if (limits.mapLimit > 0) {
        if (state.orgMaps) {
          notifications.mapLimitReached = Object.values(state.orgMaps).length === limits.mapLimit;
          notifications.mapLimitExceeded = Object.values(state.orgMaps).length > limits.mapLimit;
        }
        mapKeysNotAllowed = Object.keys(state.orgMaps).slice(
          limits.mapLimit,
          Object.keys(state.orgMaps).length
        );
        const estateKeysAllowed = Object.values(state.orgMaps)
          .filter((el: any) => !mapKeysNotAllowed.includes(el.id))
          .map((el: any) => el.estateId)
          .slice(0, limits.estateLimit);
        estateKeysNotAllowed = Object.keys(state.orgEstates).filter(
          (el: any) => !estateKeysAllowed.includes(el)
        );
      }
      if (notifications.estateLimitReached && notifications.mapLimitReached) {
        notifications.estateAndMapLimitReached = true;
      }
      if (notifications.estateLimitExceeded && notifications.mapLimitExceeded) {
        notifications.estateAndMapLimitExceeded = true;
      }
      if (limits.areaLimit > 0 && userOrg.statistics) {
        const percentage = (userOrg.statistics.mapTotal / limits.areaLimit) * 100;
        notifications.areaLimit95PercentReached = percentage >= 95;
        notifications.areaLimitReached = percentage >= 100;
        notifications.areaLimitExceeded = percentage > 110;
      }
      setAppUpgradeNotifications(notifications);

      // Set limits reached
      limitsReached.teamLimitReached =
        notifications.teamLimitReached || notifications.teamLimitExceeded;
      limitsReached.estateLimitReached =
        notifications.estateLimitReached || notifications.estateLimitExceeded;
      limitsReached.mapLimitReached =
        notifications.mapLimitReached || notifications.mapLimitExceeded;
      limitsReached.areaLimitReached = notifications.areaLimitExceeded;
      // TODO : INSERT TO ACTUALLY LIMIT APP
      // setAppUpgradeLimitsReached(limitsReached);
      // setEstateAndMapKeysAllowed({ estates: estateKeysNotAllowed, maps: mapKeysNotAllowed });
    }
  }, [userOrg, appConfig, state.orgEstates, state.orgMaps]);

  // --- Fetch data on login --- //
  useEffect(() => {
    if (isLoggedIn && user?.id) {
      if (!userMapsAndProjectsFetchedRef.current) {
        fetchMap([user.id], "userId");
        fetchProject([user.id], "userId");
        userMapsAndProjectsFetchedRef.current = true;
      }
      if (userOrg && userOrg.id && user.orgRole === "admin" && !orgEstatesFetchedRef.current) {
        fetchOrgEstates([userOrg.id], "orgId");
        orgEstatesFetchedRef.current = true;
      }
    }
  }, [isLoggedIn, user, userOrg, dispatch]);

  useEffect(() => {
    if (orgTeam) {
      // Fetch team maps
      const teamKeys = Object.keys(orgTeam);
      const teamMapsKeys = Object.keys(state.teamMaps);
      const diff = teamKeys.filter((item) => !teamMapsKeys.includes(item));
      diff.forEach((el) => {
        fetchTeamMemberMaps(el);
        fetchTeamMemberProjects(el);
      });
    }
  }, [orgTeam]);

  // Set selected species scheme id
  useEffect(() => {
    if (state.selectedMapId && state.maps[state.selectedMapId]) {
      const map = state.maps[state.selectedMapId];
      if (!map.speciesSchemeId) setSelectedSpeciesSchemeId(null);
      if (map.speciesSchemeId !== selectedSpeciesSchemeId) {
        setSelectedSpeciesSchemeId(map.speciesSchemeId);
      }
    }
  }, [state.selectedMapId, state.maps]);

  // --- Fetch data on changes --- //
  // Fetch estate if not yet fetched
  useEffect(() => {
    if (isLoggedIn && state.selectedEstateId && !state.estates[state.selectedEstateId]) {
      fetchEstate([state.selectedEstateId], "estateId");
    }
  }, [state.selectedEstateId]);

  // Fetch map if not yet fetched
  useEffect(() => {
    // Fetch map
    if (isLoggedIn && state.selectedMapId && !state.maps[state.selectedMapId]) {
      fetchMap([state.selectedMapId], "mapId", user?.authType);
    }
    // Check if roles has been fertched
    if (
      isLoggedIn &&
      state.selectedMapId &&
      state.maps[state.selectedMapId] &&
      !state.maps[state.selectedMapId].roles
    ) {
      fetchDataRoles(state.selectedMapId, "maps");
    }
    selectedMapIdRef.current = state.selectedMapId;
  }, [state.selectedMapId]);

  // Fetch project if not yet fetched
  useEffect(() => {
    if (isLoggedIn && state.selectedProjectId && !state.projects[state.selectedProjectId]) {
      fetchProject([state.selectedProjectId], "projectId", user?.authType);
    }
    // Check if roles has been fertched
    if (
      isLoggedIn &&
      state.selectedProjectId &&
      state.projects[state.selectedProjectId] &&
      !state.projects[state.selectedProjectId].roles
    ) {
      fetchDataRoles(state.selectedProjectId, "projects");
    }
    selectedProjectIdRef.current = state.selectedProjectId;
  }, [state.selectedProjectId]);

  // Set auth reducer country codes according to user estates
  useEffect(() => {
    if (state.estates && !firstRenderRef.current) {
      const countryCodes: string[] = [];
      Object.values<EstateProps>(state.estates).forEach((estate: EstateProps) => {
        const estateCountryCode = estate.country as TCountryCode;
        const estateCountryStateCode = estate.countryState as string;
        const combinedCountryCode = (estateCountryStateCode && estateCountryStateCode !== "") ? estateCountryCode + "-" + estateCountryStateCode : null;
        // Split 
        if (estateCountryCode && !countryCodes.includes(estateCountryCode)) {
          countryCodes.push(estateCountryCode);
        }
        if (combinedCountryCode && !countryCodes.includes(combinedCountryCode)) {
          countryCodes.push(combinedCountryCode);
        }
      });
      setCountryCodes(countryCodes);
    }
    firstRenderRef.current = false;
  }, [state.estates]);

  // --- Check for config updates based on selected map --- //
  useEffect(() => {
    if (user && state.selectedMapId && state.maps[state.selectedMapId]) {
      const mapRoles = state.maps[state.selectedMapId].roles;
      const userId = user.id as string;
      const config = getAppConfigFromMapRole(mapRoles, userId, "map");
      // @ts-ignore
      if (config.layout.currentMapRole !== appConfig.layout.currentMapRole) {
        // @ts-ignore
        onChangeAppFunctionalityConfig(config);
      }
    }
  }, [state.selectedMapId, state.maps, user, appConfig.layout.currentMapRole]);
  // --- Check for config updates based on selected project --- //
  useEffect(() => {
    if (user && state.selectedProjectId && state.projects[state.selectedProjectId]) {
      const projectRoles = state.projects[state.selectedProjectId].roles;
      const userId = user.id as string;
      const config = getAppConfigFromMapRole(projectRoles, userId, "project");
      // @ts-ignore
      if (config.layout.currentProjectRole !== appConfig.layout.currentProjectRole) {
        // @ts-ignore
        onChangeAppFunctionalityConfig(config);
      }
    }
  }, [state.selectedProjectId, state.projects, user, appConfig.layout.currentProjectRole]);

  // Reset reducer state
  const resetState = () => {
    // Internal states
    orgEstatesFetchedRef.current = false;
    userMapsAndProjectsFetchedRef.current = false;
    orgElementEstateChangedIdRef.current = null;
    fetchedEstatesById = [];
    // Reducer state
    dispatch({
      type: ESTATES_AND_MAPS_RESET_STATE,
    });
  };
  // Set selected estate id in state
  const setSelectedEstateId = (id: string | null) => {
    dispatch({
      type: SET_SELECTED_ESTATE_ID,
      id: id,
    });
  };

  // Set selected map id in state
  const setSelectedMapId = async (id: string | null) => {
    dispatch({
      type: SET_SELECTED_MAP_ID,
      id: id,
    });
  };

  const setSelectedProjectId = (id: string | null) => {
    dispatch({
      type: SET_SELECTED_PROJECT_ID,
      id: id,
    });
  };

  const setHasZoomed = (state: "overview" | "estate" | "map" | "feature" | null) => {
    dispatch({
      type: SET_HAS_ZOOMED,
      payload: { hasZoomed: state },
    });
  };

  const setAppUpgradeNotifications = (notifications: AppUpgradeNotifications) => {
    dispatch({
      type: SET_APP_UPGRADE_NOTIFICATIONS,
      payload: { appUpgradeNotifications: notifications },
    });
  };

  const setAppUpgradeNotificationsDismissed = (
    dismissedNotification: keyof AppUpgradeNotifications
  ) => {
    const notifications = state.appUpgradeNotifications;
    if (notifications) {
      notifications[dismissedNotification] = true;
      setAppUpgradeNotifications(notifications);
    }
  };

  const setAppUpgradeLimits = (limits: AppUpgradeLimits) => {
    dispatch({
      type: SET_APP_UPGRADE_LIMITS,
      payload: { appUpgradeLimits: limits },
    });
  };

  const setAppUpgradeLimitsReached = (limitsReached: AppUpgradeLimitsReached) => {
    dispatch({
      type: SET_APP_UPGRADE_LIMITS_REACHED,
      payload: { appUpgradeLimitsReached: limitsReached },
    });
  };

  const setEstateRowExpanded = (id: string, expanded: boolean) => {
    dispatch({
      type: SET_ESTATE_ROW_EXPANDED,
      payload: { id, expanded },
    });
  }

  const setTeamRowExpanded = (id: string, expanded: boolean) => {
    dispatch({
      type: SET_TEAM_ROW_EXPANDED,
      payload: { id, expanded },
    });
  }

  const setContactRowExpanded = (id: string, expanded: boolean) => {
    dispatch({
      type: SET_CONTACT_ROW_EXPANDED,
      payload: { id, expanded },
    });
  }

  const setEstateAndMapKeysAllowed = (
    keys: EstatesAndMapDataProps["estateAndMapKeysNotAllowed"]
  ) => {
    dispatch({
      type: SET_ESTATE_AND_MAP_KEYS_NOT_ALLOWED,
      payload: { estateAndMapKeysNotAllowed: keys },
    });
  };

  // Fetch estate
  const fetchEstate = async (ids: string[], type: "estateId" | "userId") => {
    try {
      // Setup query
      let q = null;
      if (type === "userId") {
        q = query(
          collection(db, "estates"),
          where("users", "array-contains", ids[0]),
          where("status", "!=", "deleted")
        );
      } else {
        q = query(
          collection(db, "estates"),
          where("id", "in", ids),
          where("status", "!=", "deleted")
        );
      }
      // Run query
      const unsub = onSnapshot(
        q,
        (qs) => {
          qs.docChanges().forEach((change: any) => {
            // Also fetch the user roles from private document
            switch (change.type) {
              case "added":
                dispatch({
                  type: ADDED_ESTATE_SUCCESS,
                  payload: { dataConv: { ...change.doc.data() } },
                });
                fetchDataRoles(change.doc.id, "estates");
                break;
              case "modified":
                dispatch({
                  type: MODIFIED_ESTATE_SUCCESS,
                  payload: { dataConv: { ...change.doc.data() } },
                });
                break;
              case "removed":
                const data = change.doc.data();
                // Remove document event listeners
                if (privateDocumentEventListeners[data.id])
                  privateDocumentEventListeners[data.id]();
                dispatch({
                  type: REMOVED_ESTATE_SUCCESS,
                  payload: { dataConv: { ...change.doc.data() } },
                });
                break;
              default:
            }
          });
        },
        (error) => {
          console.info("ERROR TEST FETCH ESTATES", error);
        }
      );
      // if (type === "estateId") {
      //    fetchedEstatesById.push(ids[0]);
      // }
      firestoreEventListeners.push(unsub);
    } catch (error) {
      dispatch({ type: ESTATES_AND_MAPS_API_CALL_FAIL, error: error });
    }
  };

  // Fetch org estates
  const fetchOrgEstates = async (ids: string[], type: "estateId" | "orgId") => {
    try {
      // Query
      let q = null;
      if (type === "orgId") {
        q = query(
          collection(db, "estates"),
          where("orgId", "==", ids[0]),
          where("status", "!=", "deleted")
        );
      } else {
        q = query(
          collection(db, "estates"),
          where("id", "in", ids),
          where("status", "!=", "deleted")
        );
      }
      // Run query
      const unsub = onSnapshot(
        q,
        (qs) => {
          qs.docChanges().forEach(async (change: any) => {
            switch (change.type) {
              case "added":
                dispatch({
                  type: ADDED_ORG_ESTATE_SUCCESS,
                  payload: { dataConv: { ...change.doc.data() } },
                });
                fetchDataRoles(change.doc.id, "orgEstates");
                fetchOrgMap([change.doc.id]);
                fetchOrgProject([change.doc.id]);
                break;
              case "modified":
                dispatch({
                  type: MODIFIED_ORG_ESTATE_SUCCESS,
                  payload: { dataConv: { ...change.doc.data() } },
                });
                break;
              case "removed":
                const data = change.doc.data();
                if (privateDocumentEventListeners[data.id])
                  privateDocumentEventListeners[data.id]();
                dispatch({
                  type: REMOVED_ORG_ESTATE_SUCCESS,
                  payload: { dataConv: { ...change.doc.data() } },
                });
                break;
              default:
            }
          });
        },
        (error) => {
          console.info("ERROR TEST FETCH ORG ESTATES", error);
        }
      );
      firestoreEventListeners.push(unsub);
    } catch (error) {
      console.error("Cant fetch org estates", error);
    }
  };

  // Fetch map
  const fetchMap = async (
    ids: string[],
    type: "mapId" | "estateId" | "userId",
    authType?: "anonymous" | "normal"
  ) => {
    try {
      // Setup query
      let q = null;
      if (type === "userId") {
        if (ids.length > 1) return;
        q = query(
          collection(db, "maps"),
          where("users", "array-contains", ids[0]),
          where("status", "!=", "deleted")
        );
      } else {
        if (type === "estateId") {
          if (ids.length > 1) return;
          if (!authType || authType === "anonymous") {
            q = query(
              collection(db, "maps"),
              where("estateId", "==", ids[0]),
              where("audienceActive", "==", true),
              where("status", "!=", "deleted")
            );
          } else {
            q = query(
              collection(db, "maps"),
              where("estateId", "==", ids[0]),
              where("status", "!=", "deleted")
            );
          }
        } else {
          if (!authType || authType === "anonymous") {
            q = query(
              collection(db, "maps"),
              where("id", "in", ids),
              where("audienceActive", "==", true),
              where("status", "!=", "deleted")
            );
          } else {
            q = query(collection(db, "maps"), where("id", "in", ids));
          }
        }
      }
      // Run query
      const unsub = onSnapshot(
        q,
        (qs) => {
          qs.docChanges().forEach(async (change: any) => {
            // Also fetch the user roles from private document
            switch (change.type) {
              case "added":
                dispatch({
                  type: ADDED_MAP_SUCCESS,
                  payload: {
                    dataConv: {
                      ...change.doc.data(),
                      mapFeature: change.doc.data().mapFeature
                        ? fromFirestoreFeatureToGeoJson({
                            ...change.doc.data(),
                            ...change.doc.data().mapFeature,
                          })
                        : null,
                    },
                  },
                });
                // Fetch data roles
                fetchDataRoles(change.doc.id, "maps");
                // Fetch species scheme if pressent on map data
                if (change.doc.data().speciesSchemeId) {
                  fetchSpeciesScheme(change.doc.data().speciesSchemeId);
                }
                // Check if estate is already fetched
                const estateId = change.doc.data().estateId;
                if (estateId) {
                  if (fetchedEstatesById.includes(estateId)) return;
                  fetchedEstatesById.push(estateId);
                  fetchEstate([estateId], "estateId");
                }
                break;
              case "modified":
                dispatch({
                  type: MODIFIED_MAP_SUCCESS,
                  payload: {
                    dataConv: {
                      ...change.doc.data(),
                      mapFeature: change.doc.data().mapFeature
                        ? fromFirestoreFeatureToGeoJson({
                            ...change.doc.data(),
                            ...change.doc.data().mapFeature,
                          })
                        : null,
                    },
                  },
                });
                break;
              case "removed":
                const data = change.doc.data();
                if (selectedMapIdRef.current === data.id) {
                  setSelectedEstateId(null);
                  setSelectedMapId(null);
                  setHasZoomed("estate");
                }
                // Remove document event listener
                if (privateDocumentEventListeners["maps/" + data.id]) {
                  privateDocumentEventListeners["maps/" + data.id]();
                }
                dispatch({
                  type: REMOVED_MAP_SUCCESS,
                  payload: {
                    dataConv: {
                      ...data,
                      mapFeature: change.doc.data().mapFeature
                        ? fromFirestoreFeatureToGeoJson({
                            ...change.doc.data(),
                            ...change.doc.data().mapFeature,
                          })
                        : null,
                    },
                  },
                });
                break;
              default:
            }
          });
        },
        (error) => {
          console.info("ERROR TEST", error);
        }
      );
      firestoreEventListeners.push(unsub);
    } catch (error) {
      dispatch({ type: ESTATES_AND_MAPS_API_CALL_FAIL, error: error });
    }
  };

  // Fetch org maps
  const fetchOrgMap = async (estateIds: string[]) => {
    try {
      // Query
      const q = query(
        collection(db, "maps"),
        where("estateId", "in", estateIds),
        where("status", "!=", "deleted")
      );
      // Run query
      const unsub = onSnapshot(
        q,
        (qs) => {
          qs.docChanges().forEach(async (change: any) => {
            switch (change.type) {
              case "added":
                dispatch({
                  type: ADDED_ORG_MAP_SUCCESS,
                  payload: {
                    dataConv: {
                      ...change.doc.data(),
                      mapFeature: change.doc.data().mapFeature
                        ? fromFirestoreFeatureToGeoJson({
                            ...change.doc.data(),
                            ...change.doc.data().mapFeature,
                          })
                        : null,
                    },
                  },
                });
                fetchDataRoles(change.doc.id, "orgMaps");
                break;
              case "modified":
                dispatch({
                  type: MODIFIED_ORG_MAP_SUCCESS,
                  payload: {
                    dataConv: {
                      ...change.doc.data(),
                      mapFeature: change.doc.data().mapFeature
                        ? fromFirestoreFeatureToGeoJson({
                            ...change.doc.data(),
                            ...change.doc.data().mapFeature,
                          })
                        : null,
                    },
                  },
                });
                break;
              case "removed":
                const data = change.doc.data();
                if (orgElementEstateChangedIdRef.current === data.id) {
                  orgElementEstateChangedIdRef.current = null;
                  return;
                }
                if (selectedMapIdRef.current === data.id) {
                  setSelectedEstateId(null);
                  setSelectedMapId(null);
                  setHasZoomed("estate");
                }
                if (privateDocumentEventListeners["orgMaps/" + data.id])
                  privateDocumentEventListeners["orgMaps/" + data.id]();
                dispatch({
                  type: REMOVED_ORG_MAP_SUCCESS,
                  payload: {
                    dataConv: {
                      ...change.doc.data(),
                      mapFeature: change.doc.data().mapFeature
                        ? fromFirestoreFeatureToGeoJson({
                            ...change.doc.data(),
                            ...change.doc.data().mapFeature,
                          })
                        : null,
                    },
                  },
                });
                break;
              default:
            }
          });
        },
        (error) => {
          console.info("ERROR TEST FETCH ORG MAPS", error);
        }
      );
      firestoreEventListeners.push(unsub);
    } catch (error) {
      dispatch({ type: ESTATES_AND_MAPS_API_CALL_FAIL, error: error });
    }
  };

  // Fetch team map
  const fetchTeamMemberMaps = (memberId: string) => {
    try {
      const q = query(
        collection(db, "maps"),
        where("users", "array-contains", memberId),
        where("status", "!=", "deleted")
      );
      const unsub = onSnapshot(
        q,
        (qs) => {
          qs.docChanges().forEach(async (change: any) => {
            // Also fetch the user roles from private document
            switch (change.type) {
              case "added":
                dispatch({
                  type: ADDED_TEAM_MEMBER_MAP_SUCCESS,
                  payload: {
                    userId: memberId,
                    dataConv: {
                      ...change.doc.data(),
                      mapFeature: change.doc.data().mapFeature
                        ? fromFirestoreFeatureToGeoJson({
                            ...change.doc.data(),
                            ...change.doc.data().mapFeature,
                          })
                        : null,
                    },
                  },
                });
                fetchDataRoles(change.doc.id, "teamMaps", memberId);
                break;
              case "modified":
                dispatch({
                  type: MODIFIED_TEAM_MEMBER_MAP_SUCCESS,
                  payload: {
                    userId: memberId,
                    dataConv: {
                      ...change.doc.data(),
                      mapFeature: change.doc.data().mapFeature
                        ? fromFirestoreFeatureToGeoJson({
                            ...change.doc.data(),
                            ...change.doc.data().mapFeature,
                          })
                        : null,
                    },
                  },
                });
                break;
              case "removed":
                const data = change.doc.data();
                if (privateDocumentEventListeners["maps/" + data.id])
                  privateDocumentEventListeners["maps/" + data.id]();
                dispatch({
                  type: REMOVED_TEAM_MEMBER_MAP_SUCCESS,
                  payload: {
                    userId: memberId,
                    dataConv: {
                      ...data,
                      mapFeature: change.doc.data().mapFeature
                        ? fromFirestoreFeatureToGeoJson({
                            ...change.doc.data(),
                            ...change.doc.data().mapFeature,
                          })
                        : null,
                    },
                  },
                });
                break;
              default:
            }
          });
        },
        (error) => {
          console.info("ERROR TEST", error);
        }
      );
      firestoreEventListeners.push(unsub);
    } catch (error) {
      dispatch({ type: ESTATES_AND_MAPS_API_CALL_FAIL, error: error });
    }
  };

  // Fetch project
  const fetchProject = async (
    ids: string[],
    type: "projectId" | "estateId" | "userId",
    authType?: "anonymous" | "normal"
  ) => {
    try {
      // Setup query
      let q = null;
      if (type === "userId") {
        if (ids.length > 1) return;
        q = query(
          collection(db, "projects"),
          where("users", "array-contains", ids[0]),
          where("status", "!=", "deleted")
        );
      } else {
        if (type === "estateId") {
          if (ids.length > 1) return;
          if (!authType || authType === "anonymous") {
            q = query(
              collection(db, "projects"),
              where("estateId", "==", ids[0]),
              where("audienceActive", "==", true),
              where("status", "!=", "deleted")
            );
          } else {
            q = query(
              collection(db, "projects"),
              where("estateId", "==", ids[0]),
              where("status", "!=", "deleted")
            );
          }
        } else {
          if (!authType || authType === "anonymous") {
            q = query(
              collection(db, "projects"),
              where("id", "in", ids),
              where("audienceActive", "==", true),
              where("status", "!=", "deleted")
            );
          } else {
            q = query(
              collection(db, "projects"),
              where("id", "in", ids),
              where("status", "!=", "deleted")
            );
          }
        }
      }
      // Run query
      const unsub = onSnapshot(
        q,
        (qs) => {
          qs.docChanges().forEach(async (change: any) => {
            // Also fetch the user roles from private document
            switch (change.type) {
              case "added":
                dispatch({
                  type: ADDED_PROJECT_SUCCESS,
                  payload: {
                    dataConv: {
                      ...change.doc.data(),
                      mapFeature: change.doc.data().mapFeature
                        ? fromFirestoreFeatureToGeoJson({
                            ...change.doc.data(),
                            ...change.doc.data().mapFeature,
                          })
                        : null,
                    },
                  },
                });
                fetchDataRoles(change.doc.id, "projects");
                // Check if estate is fetched
                const estateId = change.doc.data().estateId;
                if (estateId) {
                  if (fetchedEstatesById.includes(estateId)) return;
                  fetchedEstatesById.push(estateId);
                  fetchEstate([estateId], "estateId");
                }
                break;
              case "modified":
                dispatch({
                  type: MODIFIED_PROJECT_SUCCESS,
                  payload: {
                    dataConv: {
                      ...change.doc.data(),
                      mapFeature: change.doc.data().mapFeature
                        ? fromFirestoreFeatureToGeoJson({
                            ...change.doc.data(),
                            ...change.doc.data().mapFeature,
                          })
                        : null,
                    },
                  },
                });
                break;
              case "removed":
                const data = change.doc.data();
                if (selectedProjectIdRef.current === data.id) {
                  setSelectedEstateId(null);
                  setSelectedProjectId(null);
                  setHasZoomed("estate");
                }
                // Remove document event listener
                if (privateDocumentEventListeners["projects/" + data.id])
                  privateDocumentEventListeners["projects/" + data.id]();
                dispatch({
                  type: REMOVED_PROJECT_SUCCESS,
                  payload: {
                    dataConv: {
                      ...data,
                      mapFeature: change.doc.data().mapFeature
                        ? fromFirestoreFeatureToGeoJson({
                            ...change.doc.data(),
                            ...change.doc.data().mapFeature,
                          })
                        : null,
                    },
                  },
                });
                break;
              default:
            }
          });
        },
        (error) => {
          console.info("ERROR TEST", error);
        }
      );
      firestoreEventListeners.push(unsub);
    } catch (error) {
      dispatch({ type: ESTATES_AND_MAPS_API_CALL_FAIL, error: error });
    }
  };

  const fetchOrgProject = async (estateIds: string[]) => {
    try {
      // Query
      const q = query(
        collection(db, "projects"),
        where("estateId", "in", estateIds),
        where("status", "!=", "deleted")
      );
      // Run query
      const unsub = onSnapshot(
        q,
        (qs) => {
          qs.docChanges().forEach(async (change: any) => {
            switch (change.type) {
              case "added":
                dispatch({
                  type: ADDED_ORG_PROJECT_SUCCESS,
                  payload: {
                    dataConv: {
                      ...change.doc.data(),
                      mapFeature: change.doc.data().mapFeature
                        ? fromFirestoreFeatureToGeoJson({
                            ...change.doc.data(),
                            ...change.doc.data().mapFeature,
                          })
                        : null,
                    },
                  },
                });
                fetchDataRoles(change.doc.id, "orgProjects");
                break;
              case "modified":
                dispatch({
                  type: MODIFIED_ORG_PROJECT_SUCCESS,
                  payload: {
                    dataConv: {
                      ...change.doc.data(),
                      mapFeature: change.doc.data().mapFeature
                        ? fromFirestoreFeatureToGeoJson({
                            ...change.doc.data(),
                            ...change.doc.data().mapFeature,
                          })
                        : null,
                    },
                  },
                });
                break;
              case "removed":
                const data = change.doc.data();
                if (orgElementEstateChangedIdRef.current === data.id) {
                  orgElementEstateChangedIdRef.current = null;
                  return;
                }
                if (selectedProjectIdRef.current === data.id) {
                  setSelectedEstateId(null);
                  setSelectedProjectId(null);
                  setHasZoomed("estate");
                }
                if (privateDocumentEventListeners["orgProjects/" + data.id])
                  privateDocumentEventListeners["orgProjects/" + data.id]();
                dispatch({
                  type: REMOVED_ORG_PROJECT_SUCCESS,
                  payload: {
                    dataConv: {
                      ...change.doc.data(),
                      mapFeature: change.doc.data().mapFeature
                        ? fromFirestoreFeatureToGeoJson({
                            ...change.doc.data(),
                            ...change.doc.data().mapFeature,
                          })
                        : null,
                    },
                  },
                });
                break;
              default:
            }
          });
        },
        (error) => {
          console.info("ERROR TEST FETCH ORG Projects", error);
        }
      );
      firestoreEventListeners.push(unsub);
    } catch (error) {
      dispatch({ type: ESTATES_AND_MAPS_API_CALL_FAIL, error: error });
    }
  };

  const fetchTeamMemberProjects = (memberId: string) => {
    try {
      const q = query(
        collection(db, "projects"),
        where("users", "array-contains", memberId),
        where("status", "!=", "deleted")
      );
      const unsub = onSnapshot(
        q,
        (qs) => {
          qs.docChanges().forEach(async (change: any) => {
            // Also fetch the user roles from private document
            switch (change.type) {
              case "added":
                dispatch({
                  type: ADDED_TEAM_MEMBER_PROJECT_SUCCESS,
                  payload: {
                    userId: memberId,
                    dataConv: {
                      ...change.doc.data(),
                      mapFeature: change.doc.data().mapFeature
                        ? fromFirestoreFeatureToGeoJson({
                            ...change.doc.data(),
                            ...change.doc.data().mapFeature,
                          })
                        : null,
                    },
                  },
                });
                fetchDataRoles(change.doc.id, "teamProjects", memberId);
                break;
              case "modified":
                dispatch({
                  type: MODIFIED_TEAM_MEMBER_PROJECT_SUCCESS,
                  payload: {
                    userId: memberId,
                    dataConv: {
                      ...change.doc.data(),
                      mapFeature: change.doc.data().mapFeature
                        ? fromFirestoreFeatureToGeoJson({
                            ...change.doc.data(),
                            ...change.doc.data().mapFeature,
                          })
                        : null,
                    },
                  },
                });
                break;
              case "removed":
                const data = change.doc.data();
                if (privateDocumentEventListeners["projects/" + data.id])
                  privateDocumentEventListeners["projects/" + data.id]();
                dispatch({
                  type: REMOVED_TEAM_MEMBER_PROJECT_SUCCESS,
                  payload: {
                    userId: memberId,
                    dataConv: {
                      ...data,
                      mapFeature: change.doc.data().mapFeature
                        ? fromFirestoreFeatureToGeoJson({
                            ...change.doc.data(),
                            ...change.doc.data().mapFeature,
                          })
                        : null,
                    },
                  },
                });
                break;
              default:
            }
          });
        },
        (error) => {
          console.info("ERROR TEST", error);
        }
      );
      firestoreEventListeners.push(unsub);
    } catch (error) {
      console.error(error);
      dispatch({ type: ESTATES_AND_MAPS_API_CALL_FAIL, error: error });
    }
  };

  // Fetch data roles ("estates", "maps", "projects")
  const fetchDataRoles = async (
    id: string,
    type:
      | "estates"
      | "maps"
      | "projects"
      | "orgEstates"
      | "orgMaps"
      | "orgProjects"
      | "teamMaps"
      | "teamProjects",
    userId?: string
  ) => {
    dispatch({ type: ESTATES_AND_MAPS_API_CALL_START });
    try {
      let pathType = type.toLocaleLowerCase().replace("org", "").replace("team", "");
      const path = `${pathType}/${id}/private_data/private`;
      const unsub = onSnapshot(
        doc(db, path),
        async (doc) => {
          const data = { ...doc?.data() };
          dispatch({
            type: ADDED_DATA_ROLES_SUCCESS,
            id: id,
            payload: {
              userId: userId,
              roles: data.roles,
              type: type,
            },
          });
        },
        (error) => {
          console.info("ERROR TEST", error);
        }
      );
      privateDocumentEventListeners[type + "/" + id] = unsub;
    } catch (error) {
      console.error("ROLES ERROR", error);
      dispatch({ type: ESTATES_AND_MAPS_API_CALL_FAIL, error: error });
    }
  };

  // --- Save/Update Events --- //
  // Save estate
  const saveEstate = async (data: NewEstateProps, id: string | null) => {
    dispatch({ type: ESTATES_AND_MAPS_API_CALL_START });
    try {
      // Check for existing estate
      if (id) {
        // Update estate data
        const docRef = doc(db, `estates/${id}`);
        await updateDoc(docRef, {
          ...dataObjectWithoutUndefinedFields(data),
          lastModifiedDate: dayjs().format(),
        });
      } else {
        // Create estate document
        await callCloudFunction("createMetaData", {
          ...dataObjectWithoutUndefinedFields(data),
          metaDataType: "estate",
          orgId: userOrg?.id,
        });
        // Log event in mixpanel
        // mixpanel.track(mixpanelEvents.estateCreated);
        callCloudFunction("trackEvent", {event: mixpanelEvents.estateCreated});
        
      }
      dispatch({ type: SAVE_ESTATE_SUCCESS });
    } catch (error) {
      console.error("Error saving estate", error);
      dispatch({ type: ESTATES_AND_MAPS_API_CALL_FAIL, error: error });
    }
  };

  // Save map
  const saveMap = async (data: NewMapProps, id?: string) => {
    dispatch({ type: ESTATES_AND_MAPS_API_CALL_START });
    try {
      if (id) {
        orgElementEstateChangedIdRef.current = id;
        // Update map data
        const docRef = doc(db, `maps/${id}`);
        await updateDoc(docRef, {
          ...dataObjectWithoutUndefinedFields(data),
          lastModifiedDate: dayjs().format(),
        });
      } else {
        // Create map document
        await callCloudFunction("createMetaData", {
          ...dataObjectWithoutUndefinedFields(data),
          metaDataType: "map",
          orgId: userOrg?.id,
        });
      }
      // Log event in mixpanel
      // mixpanel.track(mixpanelEvents.mapCreated);
      callCloudFunction("trackEvent", {event: mixpanelEvents.mapCreated});
      dispatch({ type: SAVE_MAP_SUCCESS });
    } catch (error) {
      dispatch({ type: ESTATES_AND_MAPS_API_CALL_FAIL, error: error });
    }
  };
  const saveProject = async (data: NewProjectProps | any, id?: string) => {
    dispatch({ type: ESTATES_AND_MAPS_API_CALL_START });
    try {
      if (id) {
        orgElementEstateChangedIdRef.current = id;
        // Update project data
        const docRef = doc(db, `projects/${id}`);
        await updateDoc(docRef, {
          ...dataObjectWithoutUndefinedFields(data),
          lastModifiedDate: dayjs().format(),
        });
      } else {
        // Create project document
        await callCloudFunction("createMetaData", {
          ...dataObjectWithoutUndefinedFields(data),
          metaDataType: "project",
          orgId: userOrg?.id,
        });
        // Log event in mixpanel
        callCloudFunction("trackEvent", {event: mixpanelEvents.projectCreated});
      }
      dispatch({ type: SAVE_PROJECT_SUCCESS });
    } catch (error) {
      dispatch({ type: ESTATES_AND_MAPS_API_CALL_FAIL, error: error });
    }
  };

  const shareProjectActivity = async (
    projectId: string,
    activity: { id: string; name: string },
    responsible: ActivityResponsible
  ) => {
    try {
      // Input checking
      if (
        responsible.type === "contact" &&
        responsible.status === "invite-pending" &&
        !responsible.email
      )
        throw new Error(
          "Share project activity error. Missing email when sharing with new contact!"
        );
      if (responsible.status !== "invite-pending" && !responsible.id)
        throw new Error(
          "Share project activity error. Missing id when sharing with existing contact or team member!"
        );
      // Get project data
      const project = state.projects[projectId];
      // If user already has access to project, return
      if (project.users.includes(responsible.id)) return;
      // Else share project
      // Form data objects
      const projects: DataShareItem[] = [
        {
          inviterId: user?.id!,
          id: projectId,
          role: "editor",
          categories: null,
          expireDate: null,
        },
      ];
      let data: any = {
        orgId: userOrg?.id,
        projects: projects,
      };
      let functionType: CloudFunctionNames | null = null;
      // Check for responsible type and format data accordingly
      if (responsible.type === "team") {
        // Update team member
        functionType = "updateTeamMember";
        data = { ...data, id: responsible.id };
      } else if (responsible.type === "contact" && !responsible.id) {
        // Create new contact
        functionType = "createContact";
        data = { ...data, email: responsible.email };
      } else if (responsible.type === "contact" && responsible.id) {
        // Update contact
        functionType = "updateContact";
        data = {
          ...dataObjectWithoutUndefinedFields(data),
          id: responsible.id,
          userCreated: responsible.status !== "invite-pending",
        };
      } else {
        throw new Error("Share project activity error. Unknown responsible type!");
      }
      // Fire snackbar
      snackDispatch(
        openSnackbar({
          open: true,
          message: translate("snackbar:share-activity-initiated"),
          variant: "alert",
          severity: "info",
        })
      );
      const result = await callCloudFunction(functionType, data);
      return result;
    } catch (error) {
      dispatch({ type: ESTATES_AND_MAPS_API_CALL_FAIL, error: error });
    }
    return null;
  };

  // Delete estate
  const deleteEstate = async (id: string) => {
    dispatch({ type: ESTATES_AND_MAPS_API_CALL_START });
    try {
      if (state.selectedEstateId === id) setSelectedEstateId(null);
      await callCloudFunction("setDataStatus", {
        id,
        type: "estates",
        status: "deleted",
      });
      // Log event in mixpanel
      // mixpanel.track(mixpanelEvents.estateDeleted);
      callCloudFunction("trackEvent", {event: mixpanelEvents.estateDeleted});
      dispatch({ type: DELETE_ESTATE_SUCCESS });
    } catch (error) {
      dispatch({ type: ESTATES_AND_MAPS_API_CALL_FAIL, error: error });
    }
  };

  // Delete map
  const deleteMap = async (id: string) => {
    dispatch({ type: ESTATES_AND_MAPS_API_CALL_START });
    try {
      if (state.selectedMapId === id) setSelectedMapId(null);
      await callCloudFunction("setDataStatus", {
        id,
        type: "maps",
        status: "deleted",
      });
      // Log event in mixpanel
      // mixpanel.track(mixpanelEvents.mapDeleted);
      callCloudFunction("trackEvent", {event: mixpanelEvents.mapDeleted});
      dispatch({ type: DELETE_MAP_SUCCESS });
    } catch (error) {
      dispatch({ type: ESTATES_AND_MAPS_API_CALL_FAIL, error: error });
    }
  };

  // Delete project
  const deleteProject = async (id: string) => {
    dispatch({ type: ESTATES_AND_MAPS_API_CALL_START });
    try {
      if (state.selectedProjectId === id) {
        setSelectedProjectId(null);
      }
      await callCloudFunction("setDataStatus", {
        id,
        type: "projects",
        status: "deleted",
      });
      // Log event in mixpanel
      // mixpanel.track(mixpanelEvents.projectDeleted);
      callCloudFunction("trackEvent", {event: mixpanelEvents.projectDeleted});
      dispatch({ type: DELETE_PROJECT_SUCCESS });
    } catch (error) {
      dispatch({ type: ESTATES_AND_MAPS_API_CALL_FAIL, error: error });
    }
  };

  return (
    <EstatesAndMapsContext.Provider
      value={{
        ...state,
        setSelectedEstateId,
        setSelectedMapId,
        setSelectedProjectId,
        setHasZoomed,
        saveEstate,
        saveMap,
        saveProject,
        shareProjectActivity,
        deleteEstate,
        deleteMap,
        deleteProject,
        setAppUpgradeNotificationsDismissed,
        setEstateAndMapKeysAllowed,
        setEstateRowExpanded,
        setTeamRowExpanded,
        setContactRowExpanded,
      }}
    >
      {children}
    </EstatesAndMapsContext.Provider>
  );
}

export default EstatesAndMapsContext;
