// action - app settings reducer
export const APP_SETTINGS_RESET_STATE = '@appSettings/APP_SETTINGS_RESET_STATE';
export const SET_TRIAL_SUBSCRIPTION = '@appSettings/SET_TRIAL_SUBSCRIPTION';
export const SET_USER_LANGUAGE = '@appSettings/SET_USER_LANGUAGE'
export const SET_MAP_BACKGROUND_LAYER = '@appSettings/SET_MAP_BACKGROUND_LAYER';
export const SET_MAP_OVERLAY_LAYERS = '@appSettings/SET_MAP_OVERLAY_LAYER';
export const SET_MAP_CATEGORIES = '@appSettings/SET_MAP_CATEGORIES';
export const SET_MAP_CATEGORY_FILTER_STATE = '@appSettings/SET_MAP_CATEGORY_FILTER_STATE';
export const SET_MAP_DISPLAY_TOTAL_VALUES = '@appSettings/SET_MAP_DISPLAY_TOTAL_VALUES';
export const SET_LABELS_ACTIVE = '@appSettings/SET_LABELS_ACTIVE';
export const SET_TOOLTIPS_ACTIVE = '@appSettings/SET_TOOLTIPS_ACTIVE';
export const SET_MAP_DISPLAY_MAP_PERIMETER_FEATURES = '@appSettings/SET_MAP_DISPLAY_MAP_PERIMETER_FEATURES';
export const SET_INIT_STATE = '@appSettings/SET_INIT_STATE';

// action - account reducer
export const LOGIN = '@auth/LOGIN';
export const LOGOUT = '@auth/LOGOUT';
export const REGISTER = '@auth/REGISTER';
export const IS_INITIALIZED = '@auth/IS_INITIALIZED';
export const CHILDREN_IS_INITIALIZED = '@auth/CHILDREN_IS_INITIALIZED';
export const GOOGLE_PLACES_AUTOCOMPLETE_LOADED = '@auth/GOOGLE_PLACES_AUTOCOMPLETE_LOADED';
export const SET_CURRENT_SOFTWARE_VERSION = '@auth/SET_CURRENT_SOFTWARE_VERSION';
export const SET_COUNTRY_CODES = '@auth/SET_COUNTRY_CODES';
export const FETCH_USER_ORG_SUCCESS = '@auth/FETCH_USER_ORG_SUCCESS';
export const FETCH_USER_ONBOARDING_OBJECT_SUCCESS = '@auth/FETCH_USER_ONBOARDING_OBJECT_SUCCESS';
export const FETCH_ORG_PRIVATE_DATA_SUCCESS = '@auth/FETCH_ORG_PRIVATE_DATA_SUCCESS';
export const FETCH_ORG_TO_ORG_PROVIDER_SUBSCRIPTIONS_SUCCESS = '@auth/FETCH_ORG_TO_ORG_PROVIDER_SUBSCRIPTIONS_SUCCESS';
export const FETCH_ORG_TO_ORG_CONSUMER_SUBSCRIPTIONS_SUCCESS = '@auth/FETCH_ORG_TO_ORG_CONSUMER_SUBSCRIPTIONS_SUCCESS';
export const ADDED_ORG_TEAM_MEMBER_SUCCESS = '@auth/ADDED_ORG_TEAM_MEMBER_SUCCESS';
export const MODIFIED_ORG_TEAM_MEMBER_SUCCESS = '@auth/MODIFIED_ORG_TEAM_MEMBER_SUCCESS';
export const REMOVED_ORG_TEAM_MEMBER_SUCCESS = '@auth/REMOVED_ORG_TEAM_MEMBER_SUCCESS';
export const DELETE_TEAM_MEMBER_FROM_STATE = '@auth/DELETE_TEAM_MEMBER_FROM_STATE';
export const ADDED_ORG_CONTACT_SUCCESS = '@auth/ADDED_ORG_CONTACT_SUCCESS';
export const MODIFIED_ORG_CONTACT_SUCCESS = '@auth/MODIFIED_ORG_CONTACT_SUCCESS';
export const REMOVED_ORG_CONTACT_SUCCESS = '@auth/REMOVED_ORG_CONTACT_SUCCESS'
export const ORG_CONTACTS_EMPTY = '@auth/ORG_CONTACTS_EMPTY';
export const FETCH_ORG_CONTACTS_SUCCESS = '@auth/FETCH_ORG_CONTACTS_SUCCESS';
export const DELETE_CONTACT_FROM_STATE = '@auth/DELETE_CONTACT_FROM_STATE';
export const SET_URL_PARAMS = '@auth/SET_URL_PARAMS';
export const FETCH_SPECIES_DATA_SUCCESS = '@auth/FETCH_SPECIES_DATA_SUCCESS';
export const FETCH_ACTIVITY_DATA_SUCCESS = '@auth/FETCH_ACTIVITY_DATA_SUCCESS';
export const FETCH_SUBSCRIPTION_ADD_ONS_SUCCESS = '@auth/FETCH_SUBSCRIPTION_ADD_ONS_SUCCESS';
export const SET_ERROR = '@auth/SET_ERROR';
export const SET_LOADING = '@auth/SET_LOADING';
export const FETCH_EXTERNAL_WMS_LAYERS_SUCCESS = '@auth/FETCH_EXTERNAL_WMS_LAYERS_SUCCESS';
export const SET_APP_UPGRADE_NOTIFICATIONS = '@auth/SET_APP_UPGRADE_NOTIFICATIONS';
export const SET_APP_UPGRADE_LIMITS = '@auth/SET_APP_UPGRADE_LIMITS';
export const SET_APP_UPGRADE_LIMITS_REACHED = '@auth/SET_APP_UPGRADE_LIMITS_REACHED';
export const SET_ESTATE_AND_MAP_KEYS_NOT_ALLOWED = '@auth/SET_ESTATE_AND_MAP_KEYS_NOT_ALLOWED';
export const ADDED_SPECIES_SCHEME = '@auth/ADDED_SPECIES_SCHEME';
export const MODIFIED_SPECIES_SCHEME = '@auth/MODIFIED_SPECIES_SCHEME';
export const REMOVED_SPECIES_SCHEME = '@auth/REMOVED_SPECIES_SCHEME';
export const SET_SELECTED_SPECIES_SCHEME_ID = '@auth/SET_SELECTED_SPECIES_SCHEME_ID';
export const SET_ESTATE_ROW_EXPANDED = '@auth/SET_ESTATE_ROW_EXPANDED';
export const SET_TEAM_ROW_EXPANDED = '@auth/SET_TEAM_ROW_EXPANDED';
export const SET_CONTACT_ROW_EXPANDED = '@auth/SET_CONTACT_ROW_EXPANDED';

// action - estates and maps reducer
export const ESTATES_AND_MAPS_RESET_STATE = '@estatesAndMaps/ESTATES_AND_MAPS_RESET_STATE';
export const SET_SELECTED_ESTATE_ID = '@estatesAndMaps/SET_SELECTED_ESTATE_ID';
export const SET_SELECTED_MAP_ID = '@estatesAndMaps/SET_SELECTED_MAP_ID';
export const SET_SELECTED_PROJECT_ID = '@estatesAndMaps/SET_SELECTED_PROJECT_ID';
export const SET_HAS_ZOOMED = '@estatesAndMaps/SET_HAS_ZOOMED';
export const ESTATES_AND_MAPS_API_CALL_START = '@estatesAndMaps/ESTATES_AND_MAPS_API_CALL_START';
export const ESTATES_AND_MAPS_API_CALL_FAIL = '@estatesAndMaps/ESTATES_AND_MAPS_API_CALL_FAIL';
export const ADDED_ESTATE_SUCCESS = '@estatesAndMaps/ADDED_ESTATE_SUCCESS';
export const MODIFIED_ESTATE_SUCCESS = '@estatesAndMaps/MODIFIED_ESTATE_SUCCESS';
export const REMOVED_ESTATE_SUCCESS = '@estatesAndMaps/REMOVED_ESTATE_SUCCESS';
export const FETCH_ESTATES_SUCCESS = '@estatesAndMaps/FETCT_ESTATES_SUCCESS';
export const ADDED_MAP_SUCCESS = '@estatesAndMaps/ADDED_MAP_SUCCESS';
export const MODIFIED_MAP_SUCCESS = '@estatesAndMaps/MODIFIED_MAP_SUCCESS';
export const REMOVED_MAP_SUCCESS = '@estatesAndMaps/REMOVED_MAP_SUCCESS';
export const ADDED_DATA_ROLES_SUCCESS = '@estatesAndMaps/ADDED_DATA_ROLES_SUCCESS';
export const ADDED_ORG_ESTATE_SUCCESS = '@estatesAndMaps/ADDED_ORG_ESTATE_SUCCESS';
export const MODIFIED_ORG_ESTATE_SUCCESS = '@estatesAndMaps/MODIFIED_ORG_ESTATE_SUCCESS';
export const REMOVED_ORG_ESTATE_SUCCESS = '@estatesAndMaps/REMOVED_ORG_ESTATE_SUCCESS';
export const ADDED_ORG_MAP_SUCCESS = '@estatesAndMaps/ADDED_ORG_MAP_SUCCESS';
export const MODIFIED_ORG_MAP_SUCCESS = '@estatesAndMaps/MODIFIED_ORG_MAP_SUCCESS';
export const REMOVED_ORG_MAP_SUCCESS = '@estatesAndMaps/REMOVED_ORG_MAP_SUCCESS';
export const FETCH_MAPS_SUCCESS = '@estatesAndMaps/FETCH_MAPS_SUCCESS';
export const ADDED_TEAM_MEMBER_MAP_SUCCESS = '@estatesAndMaps/ADDED_TEAM_MEMBER_MAP_SUCCESS';
export const MODIFIED_TEAM_MEMBER_MAP_SUCCESS = '@estatesAndMaps/MODIFIED_TEAM_MEMBER_MAP_SUCCESS';
export const REMOVED_TEAM_MEMBER_MAP_SUCCESS = '@estatesAndMaps/REMOVED_TEAM_MEMBER_MAP_SUCCESS';
export const ADDED_PROJECT_SUCCESS = '@estatesAndMaps/ADDED_PROJECT_SUCCESS';
export const MODIFIED_PROJECT_SUCCESS = '@estatesAndMaps/MODIFIED_PROJECT_SUCCESS';
export const REMOVED_PROJECT_SUCCESS = '@estatesAndMaps/REMOVED_PROJECT_SUCCESS';
export const ADDED_ORG_PROJECT_SUCCESS = '@estatesAndMaps/ADDED_ORG_PROJECT_SUCCESS';
export const MODIFIED_ORG_PROJECT_SUCCESS = '@estatesAndMaps/MODIFIED_ORG_PROJECT_SUCCESS';
export const REMOVED_ORG_PROJECT_SUCCESS = '@estatesAndMaps/REMOVED_ORG_PROJECT_SUCCESS';
export const ADDED_TEAM_MEMBER_PROJECT_SUCCESS = '@estatesAndMaps/ADDED_TEAM_MEMBER_PROJECT_SUCCESS';
export const MODIFIED_TEAM_MEMBER_PROJECT_SUCCESS = '@estatesAndMaps/MODIFIED_TEAM_MEMBER_PROJECT_SUCCESS';
export const REMOVED_TEAM_MEMBER_PROJECT_SUCCESS = '@estatesAndMaps/REMOVED_TEAM_MEMBER_PROJECT_SUCCESS';
export const SAVE_ESTATE_SUCCESS = '@estatesAndMaps/SAVE_ESTATE_SUCCESS';
export const SAVE_MAP_SUCCESS = '@estatesAndMaps/SAVE_MAP_SUCCESS';
export const SAVE_PROJECT_SUCCESS = '@estatesAndMaps/SAVE_PROJECT_SUCCESS';
export const DELETE_MAP_SUCCESS = '@estatesAndMaps/DELETE_MAP_SUCCESS';
export const DELETE_ESTATE_SUCCESS = '@estatesAndMaps/DELETE_ESTATE_SUCCESS';
export const DELETE_PROJECT_SUCCESS = '@estatesAndMaps/DELETE_PROJECT_SUCCESS';

// action - map data reducer
export const MAP_DATA_RESET_STATE = '@mapData/MAP_DATA_RESET_STATE';
export const SET_SELECTED_FEATURE_ID = '@mapData/SET_SELECTED_FEATURE_ID';
export const SET_SELECTED_FEATURE = '@mapData/SET_SELECTED_FEATURE';
export const SET_SELECTED_ACTIVITY_ID = '@mapData/SET_SELECTED_ACTIVITY_ID';
export const SET_REMOVE_FEATURE_ID = '@mapData/SET_REMOVE_FEATURE_ID';
export const SET_ADD_STAND_DATA = '@mapData/SET_ADD_STAND_DATA';
export const SET_USE_PROGRAM_DEFINED_SETTING = '@mapData/SET_USE_PROGRAM_DEFINED_SETTING';
export const SET_AREA_BEARING_SETTING = '@mapData/SET_AREA_BEARING_SETTING';
export const SET_FEATURE_EDIT_MODE = '@mapData/SET_FEATURE_EDIT_MODE';
export const SET_DRAW_MODE = '@mapData/SET_DRAW_MODE';
export const SET_TABLE_STATE = '@mapData/SET_TABLE_STATE';
export const SET_BOARD_STATE = '@mapData/SET_BOARD_STATE';
export const SET_ESTATE_MAP_FILTER_FEATURE_IDS = '@mapData/SET_ESTATE_MAP_FILTER_FEATURE_IDS';
export const SET_CATEGORIES = '@mapData/SET_CATEGORIES';
export const SET_MAP_CATEGORIES_FILTER_LOGIC_TYPE = '@mapData/SET_MAP_CATEGORIES_FILTER_LOGIC_TYPE';
export const SET_ACTIVE_EXTERNAL_LAYERS_LEGEND = '@mapData/SET_ACTIVE_EXTERNAL_LAYERS_LEGEND';
export const SET_NOTES_LAYER_STATE = '@mapData/SET_NOTES_LAYER_STATE';
export const MAP_DATA_API_CALL_START = '@mapData/MAP_DATA_API_CALL_START';
export const MAP_DATA_API_CALL_FAIL = '@mapData/MAP_DATA_API_CALL_FAIL';
export const ADDED_ACTIVITY_SUCCESS = '@mapData/ADDED_ACTIVITY_SUCCESS';
export const MODIFIED_ACTIVITY_SUCCESS = '@mapData/MODIFIED_ACTIVITY_SUCCESS';
export const REMOVED_ACTIVITY_SUCCESS = '@mapData/REMOVED_ACTIVITY_SUCCESS';
export const ADDED_FEATURE_SUCCESS = '@mapData/ADDED_FEATURE_SUCCESS';
export const MODIFIED_FEATURE_SUCCESS = '@mapData/MODIFIED_FEATURE_SUCCESS';
export const REMOVED_FEATURE_SUCCESS = '@mapData/REMOVED_FEATURE_SUCCESS';
export const ADDED_ACTIVITY_FEATURE_SUCCESS = '@mapData/ADDED_ACTIVITY_FEATURE_SUCCESS';
export const MODIFIED_ACTIVITY_FEATURE_SUCCESS = '@mapData/MODIFIED_ACTIVITY_FEATURE_SUCCESS';
export const REMOVED_ACTIVITY_FEATURE_SUCCESS = '@mapData/REMOVED_ACTIVITY_FEATURE_SUCCESS';
export const ADDED_THINNING_DATA_SUCCESS = '@mapData/ADDED_THINNING_DATA_SUCCESS';
export const MODIFIED_THINNING_DATA_SUCCESS = '@mapData/MODIFIED_THINNING_DATA_SUCCESS';
export const REMOVED_THINNING_DATA_SUCCESS = '@mapData/REMOVED_THINNING_DATA_SUCCESS';
export const FETCH_STAND_DATA_SUCCESS = '@mapData/FETCH_STAND_DATA_SUCCESS';
export const SAVE_ACTIVITY_SUCCESS = '@mapData/SAVE_ACTIVITY_SUCCESS';
export const SAVE_FEATURE_SUCCESS = '@mapData/SAVE_FEATURE_SUCCESS';
export const DELETE_FEATURE_SUCCESS = '@mapData/DELETE_FEATURE_SUCCESS';
export const SAVE_STAND_DATA_SUCCESS = '@mapData/SAVE_STAND_DATA_SUCCESS';
export const SAVE_THINNING_DATA_SUCCESS = '@mapData/SAVE_THINNING_DATA_SUCCESS';
export const ADDED_NOTE_SUCCESS = '@mapData/ADDED_NOTE_SUCCESS';
export const MODIFIED_NOTE_SUCCESS = '@mapData/MODIFIED_NOTE_SUCCESS';
export const REMOVED_NOTE_SUCCESS = '@mapData/REMOVED_NOTE_SUCCESS';

// action - files reducer
export const ADDED_FILE_SUCCESS = '@files/ADDED_FILE_SUCCESS';
export const MODIFIED_FILE_SUCCESS = '@files/MODIFIED_FILE_SUCCESS';
export const REMOVED_FILE_SUCCESS = '@files/REMOVED_FILE_SUCCESS';
export const UPLOAD_FILE_SUCCESS = '@files/UPLOAD_FILE_SUCCESS';
export const DOWNLOAD_FILE_SUCCESS = '@files/DOWNLOAD_FILE_SUCCESS';
export const DELETE_FILE_SUCCESS = '@files/DELETE_FILE_SUCCESS';
export const FILES_RESET_STATE = '@files/FILES_RESET_STATE';
export const FILES_API_CALL_START = '@files/FILES_API_CALL_START';
export const FILES_API_CALL_FAIL = '@files/FILES_API_CALL_FAIL';
